// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-case-study-tsx": () => import("/opt/build/repo/src/templates/CaseStudy.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-text-page-tsx": () => import("/opt/build/repo/src/templates/TextPage.tsx" /* webpackChunkName: "component---src-templates-text-page-tsx" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-tsx": () => import("/opt/build/repo/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-culture-tsx": () => import("/opt/build/repo/src/pages/culture.tsx" /* webpackChunkName: "component---src-pages-culture-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-solutions-tsx": () => import("/opt/build/repo/src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-pages-styleguide-tsx": () => import("/opt/build/repo/src/pages/Styleguide.tsx" /* webpackChunkName: "component---src-pages-styleguide-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("/opt/build/repo/src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-unsupported-browser-tsx": () => import("/opt/build/repo/src/pages/unsupported-browser.tsx" /* webpackChunkName: "component---src-pages-unsupported-browser-tsx" */),
  "component---src-pages-work-tsx": () => import("/opt/build/repo/src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

